import React, { useEffect, useState } from "react";
import { executeGraphQLQueryFinex } from "../../common/executeGraphQLQuery";
import { GET_LAST_CIBIL_INFO } from "../../queries/sangam/cibil.gql";
//import ReactStoreIndicator from "react-score-indicator";

const LoanOfferDetails = ({ cibilData }) => {
  console.log ("+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++" , cibilData)
  const getColor = (value) => {
    if (value <= 400) {
      return "red";
    } else if (value <= 700) {
      return "yellow";
    } else {
      return "#6E55B8";
    }
  };


  const getText = (value) => {
    if (value <= 400) {
      return "Low";
    } else if (value <= 700) {
      return "Medium";
    } else {
      // return "Very Good";
    }
  };

  return (
    <>
      <div className="cibilscoreflex">
        <h6>CIBIL REPORT</h6>
        {/* <div className="cibilscorebox">
            <div>
            <ReactStoreIndicator
              value={700}
              minValue={300}
              maxValue={900}
              lineWidth={20}
              lineGap={0}
            />
            <div className="cibilscore">
              <div
                style={{
                  fontSize: 32,
                  fontWeight: 600,
                  color: getColor(),
                }}
              >
                <span>700</span>
              </div>
              <div style={{ fontSize: 18, color: getColor() }}>{getText()}</div>
            </div>
            <div style={{ textAlign: "center" }}>
              <span style={{ marginRight: 100 }}>{300}</span>
              <span>{900}</span>
            </div>
            </div> */}
        {/* {console.log ("+++++++++++++++++++++++++++++++" , cibilData)}
           
          
            </div> */}

        <div>
          <div className="cibilscorecard">
            <div className="cibilscorenew">
              <div className="socreshow" style={{fontWeight : "600"}}>
                {cibilData?.data?.mobile_res?.credit_score
                  ? cibilData?.data?.mobile_res?.credit_score
                  : "N/A"}
              </div>
              <p style={{ marginBottom: "0px" , fontSize : "10px"}}>Credit Score</p>
            </div>
            <div className="cibilnamecard">
              <div className="cibilname" style={{fontSize : "10px"}}>
                {cibilData?.data?.report_summary?.name
                  ? cibilData?.data?.report_summary?.name
                  : "N/A"}
              </div>
            {cibilData?.pdf_path &&  <div className="viewreportcibil"  style={{fontSize : "10px"}}>
                <a href={cibilData?.pdf_path} target="blank">
                  View Report
                </a>
              </div>}
            </div>
          </div>
        </div>
        {/* <div>
                <h5 style={{fontSize:12}}>CREDIT Score</h5>
                <div className="creditviewreport">View Report</div>
            </div> */}
      </div>
    </>
  );
};

export default LoanOfferDetails;
