import { gql } from '@apollo/client';

export const SAVE_CUSTOMER_BASIC_DETAILS = gql`
    mutation save_additional_info($basicDetailsInput: SaveBasicDetailsInput!) {
        save_customer_basic_details(basic_details_input: $basicDetailsInput) {
            success
            error
        }
    }
`;

export const SAVE_CUSTOMER_DETAILS = gql`
mutation save_additional_info($LeadAdditionalInput: LeadAdditionalInput!) {
    save_additional_info(additionalInput: $LeadAdditionalInput, api_called_by: web) {
      co_applicant{
            ca_first_name
            ca_last_name
            ca_gender
            ca_dob
            ca_mobile
            ca_email
            ca_aadhar_no
            ca_pancard_no
            ca_cra_city
            ca_cra_address1
            ca_cra_address2
            ca_cra_state
            ca_no_of_emi
            ca_marital_status
            ca_mother_name
            ca_qualification
        }
        customer{
            father_name
            last_name
            gender
            dob
            mobile
            email
            pancard_no
            aadhar_no
            marital_status
            cra_address1
            cra_address2
            cra_city
            cra_pincode
            cra_state
            mother_name
            qualification
        }
        lead_details{
            loan_amount
            bank_id
            tenure
            annual_income
            profession
            salary_credit_mode
            company_name
            existing_emi
            no_of_emi
            existing_emi_amount
        }
    }
  }
`;

export const SAVE_CUSTOMER_PROPERTY_IDENTIFIED = gql`
    mutation saveLoanDetails($loanDetailsInput: SaveLoanDetails!) {
        save_customer_property_identified(property_identified_input: $propertyIdentifiedInput) {
            success
            error
        }
    }
`;

export const SAVE_CUSTOMER_ADDITIONAL_DETAILS = gql`
    mutation saveAdditionalDetails($additionalDetailsInput: SaveAdditionalDetails!,$insuranceDetailsInput: SaveInsuranceDetails!) {
        save_customer_additional_details(additional_details_input: $additionalDetailsInput, insurance_details_input: $insuranceDetailsInput) {
            success
            error
        }
    }
`;

export const SAVE_CUSTOMER_EMPLOYMENT_DETAILS = gql`
    mutation saveEmploymentDetails($empDetailInput: SaveEmploymentDetails!) {
        save_customer_employment_details(employment_details_input: $empDetailInput) {
            success
            error
        }
    }
`;

export const SAVE_CUSTOMER_BANK_DETAILS = gql`
    mutation saveBankDetails($bankDetailsInput: SaveBankDetails!) {
        save_customer_bank_details(bank_details_input: $bankDetailsInput) {
            success
            error
        }
    }
`;

export const SAVE_CUSTOMER_RESIDENCE_DETAILS = gql`
    mutation saveResidentialDetails($residenceDetailsInput: SaveResidentialDetails!) {
        save_customer_residential_details(residential_details_input: $residenceDetailsInput) {
            success
            error
        }
    }
`;

export const SAVE_CUSTOMER_CHARACTER_REF_DETAILS = gql`
    mutation saveCharacterRefDetails($characterRefInput: SaveCharacterRefDetails!) {
        save_customer_character_ref_details(character_ref_details: $characterRefInput) {
            success
            error
        }
    }
`;

export const SAVE_LOAN_DETAILS = gql`
mutation save_additional_info($LeadAdditionalInput: LeadAdditionalInput!) {
    save_additional_info(additionalInput: $LeadAdditionalInput, api_called_by: web) {
     
        lead_details{
            property_value
            property_type
            usage_type
            agreement_type 
            loan_amount 
            bank_id
        }
    }
  }
`;
export const SAVE_CUST_DETAILS = (user_id) => gql`
mutation save_additional_info($LeadAdditionalInput: LeadAdditionalInput!) {
    save_additional_info(additionalInput: $LeadAdditionalInput, api_called_by: web, user_id: ${user_id}) {
     
        lead_details{
            property_value
            property_type
            usage_type
            agreement_type 
            loan_amount 
            bank_id
        }
        co_applicant{
          id
          ca_first_name
        }
    }
  }
`;


export const SAVE_BANK_OFFER = gql`
mutation save_additional_info($LeadAdditionalInput: LeadAdditionalInput!) {
    save_additional_info(additionalInput: $LeadAdditionalInput, api_called_by: web) {
        
        customer{
            first_name
            last_name
            mobile
        }
        lead_details{
            loan_amount 
            company_type
            profession
            monthly_salary
            cibil_score
        }
    }
  }
`;

export const SAVE_ADDITIONAL_CIBIL_INFO = gql`
mutation save_additional_info($LeadAdditionalInput: LeadAdditionalInput!) {
  save_additional_info(
    additionalInput: $LeadAdditionalInput
    api_called_by: web
  ) {
    customer{
        lead_id,
        first_name
        last_name
        mobile
        email
        pancard_no
        aadhar_no
        dob
    }
    __typename
  }
}`

export const SAVE_NEW_DETAILS = gql`
mutation save_additional_info($LeadAdditionalInput: LeadAdditionalInput!) {
    save_additional_info(additionalInput: $LeadAdditionalInput, api_called_by: web) {
        customer{
            father_name
            last_name
            mobile
            email 
        }
    }
  }
`;

export const SAVE_DOC_REQUEST = gql`
  mutation save_doc_request($saveDocRequest: DocRequestInput!) {
    save_doc_request(saveDocRequest: $saveDocRequest)
  }
`;

export const SAVE_DOC_RECOMMENDED_LIST = gql`
  mutation save_doc_recommended_list($saveDocRecommendedList: DocRecommendedInputList!) {
    save_doc_recommended_list(saveDocRecommendedList: $saveDocRecommendedList)
  }
`;

export const UPDATE_DOC_REQUEST = gql`
  mutation update_doc_request(
    $updateDocRequest: DocRequestInput!
    $docReqId: Int!
  ) {
    update_doc_request(updateDocRequest: $updateDocRequest, docReqId: $docReqId)
  }
`;

export const GET_DOC_REQUEST_LIST = (lead_id, customerType) => { 
    return gql`
    query{
        get_doc_request_list(lead_id:${lead_id}, customer_type:"${customerType}")
        {
          id
          lead_id
          doc_id
          parent_doc_id
          is_doc_uploaded
          doc_upload_url
          customer_type
          customer_id
          status
          is_recommended
        }
    }`
}

export const SEND_NOTIFICATION_TO_CUSTOMER = (customerName, mobile, url, lead_id) => { 
    return gql`
    query{
        send_notification_to_customer(customer_name: "${customerName}", mobile: "${mobile}", url: "${url}", lead_id: ${lead_id})
    }`
}

export const GET_DETAILS_BY_PANCARD = (panCard) => gql`
  query {
    get_details_by_pancard(pan_card: "${panCard}")
  }
`;
export const GET_DETAILS_BY_PANCARD_UPDATE = (panCard,customer_type,customer_id) => gql`
  query {
    get_details_by_pancard(pan_card: "${panCard}",customer_type:"${customer_type}",customer_id:${customer_id})
  }
`;

export const GENERATE_CIBIL_PDF = (panCard,lead_id) => gql`
  query {
    generate_cibil_report(pan_card: "${panCard}",lead_id:${lead_id})
  }
`;

export const CHECK_CIBIL_CONSIGNMENT = gql`
mutation check_cibil_consignment($checkConsignmentInput: CheckConsignmentInput!) {
    check_cibil_consignment(checkConsignmentInput: $checkConsignmentInput)
  }
`;

export const GET_CIBIL_DETAILS = gql`
mutation get_cibil_details($cibilDetailsInput: CibilDetailsInput!) {
    get_cibil_details(cibilDetailsInput: $cibilDetailsInput)
  }
`;

export const SEND_CONSIGNMENT_LINK = gql`
mutation send_consignment_link($sendConsignmentLinkInput: CheckConsignmentInput!) {
    send_consignment_link(sendConsignmentLinkInput: $sendConsignmentLinkInput)
  }
`;

export const GET_CIBIL_INFO = (pan_card,report_type) => gql`
  query {
    cibil_info(pan_card: "${pan_card}",report_type:"${report_type}")
  }
`;

export const GET_LAST_CIBIL_INFO = (pan_card) => gql`
  query {
    last_cibil_info(pan_card: "${pan_card}")
  }
`; 

export const SAVE_BRE_OFFER = gql`
mutation save_additional_info($LeadAdditionalInput: LeadAdditionalInput!) {
    save_additional_info(additionalInput: $LeadAdditionalInput, api_called_by: web) {
        
        customer{
            first_name
            last_name
            mobile
        }
        lead_details{
            loan_amount 
            company_type
            profession
            monthly_salary
            cibil_score
        }
    }
  }
`;