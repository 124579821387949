import React, { useState, useEffect, useMemo } from "react";
import FormFields from "../../../src/saathi-web/MyLeads/formFields/leadFormField.json";
import { executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";
import { toast } from "react-toastify";
import dateFormat from "dateformat";
import { UPDATE_LEAD_STATUS } from "../../queries/sangam/leads.gql";
import GeneralService from "../../service/generalService";
import { gql } from "@apollo/client";
import Formsy from "formsy-react";
import FormsyInputField from "../../view/elements/FormsyInputField";
import FormsySelect from "../../view/elements/FormsySelect";
import FormsyDatePicker from "../../view/elements/FormsyDatePicker";
import secureStorage from "../../config/encrypt";
import MultiSelect from "../../view/elements/MultiSelect";
import Q from "q";

const DISBURSAL_DOC_ID = 44;

const DisbursedDetailsForm = FormFields["DISBURSED"];
const DisbursalDocsJson = FormFields["DISBURSED_DOCS"];

const DisbursalDetails = ({ leadDetail, masterdata, masterStatusData }) => {
  const [disburseDetails, setDisbursedDetails] = useState([]);
  const [expectedDate, setExpectedDisburseDate] = useState({
    expected_disbursed_date: "",
  });
  const [disbursalDocuments, setDisbursalDocuments] = useState([]);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [disburseMentDone, setdisburseMentDone] = useState("0");
  const [newPayment, setNewPayment] = useState(true);
  const [tranchConfirmation, setTranchConfirmation] = useState(null);
  const [activeTab, setActiveTab] = useState("tranchtype_0");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [existingUploadedFiles, setExistingUploadedFiles] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const user_information = secureStorage.getItem("loginUserInfo");

  useEffect(() => {
    if (leadDetail) {
      let disbursedData = leadDetail?.splitpayment || [];
      if (disbursedData.length > 0) {
        const splitPayment = disbursedData.map((data) => {
          return {
            id: data.id,
            loan_type: leadDetail?.loan_type || "0",
            tranche_type: leadDetail?.tranche_type || "0",
            loan_sub_type: leadDetail?.loan_sub_type
              ? leadDetail?.loan_sub_type.split(",")
              : [],
            product_sub_type: data?.product_sub_type || 0,
            disbursed_id: data.disbursed_id || "",
            disbursed_date: data.disbursed_date || "",
            disbursed_amount: data.disbursed_amount || 0,
            disbursed_tenure: data.disbursed_tenure || 0,
            disbursal_remark: "",
            disbursed_date: data.disbursed_date || "",
            is_tranch_verified: data.is_tranch_verified || "0",
            disbursed_roi: data.disbursed_roi || 0,
          };
        });
        setDisbursedDetails(splitPayment);
        setdisburseMentDone(leadDetail?.disbursement_done);
      }
      if (disbursedData.length == 0) {
        disbursalEmptyKey();
      } else {
        setNewPayment(false);
      }
      setExistingUploadedFiles(leadDetail.leaddocs || []);
    }
  }, [leadDetail]);

  const disbursalEmptyKey = () => {
    const newDisburasalData = [
      {
        loan_type: leadDetail?.loan_type || "",
        loan_sub_type: leadDetail?.loan_sub_type
          ? leadDetail?.loan_sub_type.split(",")
          : [],
        tranche_type: leadDetail?.tranche_type || "0",
        disbursed_id: "",
        disbursed_date: "",
        disbursed_amount: "",
        disbursed_tenure: 0,
        disbursal_remark: "",
        disbursed_date: "",
        disbursed_roi: 0,
      },
    ];
    setNewPayment(true);
    setDisbursedDetails(newDisburasalData);
  };

  const addMoreFields = () => {
    const newDisburasalData = [
      ...disburseDetails,
      {
        loan_type: leadDetail?.loan_type || "",
        loan_sub_type: leadDetail?.loan_sub_type
          ? leadDetail?.loan_sub_type.split(",")
          : [],
        product_sub_type: "",
        tranche_type: leadDetail?.tranche_type || "0",
        disbursed_id: "",
        disbursed_date: "",
        disbursed_amount: "",
        disbursed_tenure: 0,
        disbursal_remark: "",
        disbursed_date: "",
        disbursed_roi: 0,
      },
    ];
    setNewPayment(true);
    setDisbursedDetails(newDisburasalData);
    setActiveTab(`tranchtype_${newDisburasalData.length - 1}`);
  };

  useEffect(() => {
    let doc_list = [
      {
        id: DISBURSAL_DOC_ID,
        max_upload: 5,
        min_upload: 1,
        name: "Disbursed Image",
      },
    ];
    let doc_array = [];
    DisbursalDocsJson &&
      DisbursalDocsJson.map((field) => {
        doc_list.map((doc) => {
          return doc.id === field.doc_id
            ? doc_array.push(doc)
            : doc_array.push({});
        });
      });
    doc_array = doc_array.filter((obj) => Object.keys(obj).length !== 0);
    setDisbursalDocuments(doc_array);
  }, []);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata } : {};
    return options;
  }, [masterdata]);

  const handleInputChange = (event) => {
    if (event.target.id) {
      let activetabIndex = activeTab.split("_");
      activetabIndex = activetabIndex[1];
      const updatedDisburalDetails = [...disburseDetails];
      updatedDisburalDetails[activetabIndex][event.target.id] =
        event.target.value;
      setDisbursedDetails(updatedDisburalDetails);
      setExpectedDisburseDate({
        ...disburseDetails,
        expected_disbursed_date: "",
      });
    }
  };

  const handleDisbursalDone = (event) => {
    if (event.target.checked) {
      setdisburseMentDone(event.target.value);
    } else {
      setdisburseMentDone("0");
    }
  };

  const handlePatternFormatChange = (data, field_name) => {
    if (field_name && data.value !== undefined) {
      let activetabIndex = activeTab.split("_");
      activetabIndex = activetabIndex[1];
      const updatedDisburalDetails = [...disburseDetails];
      updatedDisburalDetails[activetabIndex][field_name] = data.value;
      setDisbursedDetails(updatedDisburalDetails);
    }
  };

  const handleMultiSelectChange = async (n, v, isMulti, opt) => {
    let activetabIndex = activeTab.split("_");
    activetabIndex = activetabIndex[1];
    const updatedDisburalDetails = [...disburseDetails];
    updatedDisburalDetails[activetabIndex][n] = v.map((obj) => obj[opt]);
    if (updatedDisburalDetails[activetabIndex][n].length !== 2)
      updatedDisburalDetails[activetabIndex]["product_sub_type"] = 0;
    setDisbursedDetails(updatedDisburalDetails);
  };

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      let activetabIndex = activeTab.split("_");
      activetabIndex = activetabIndex[1];
      const updatedDisburalDetails = [...disburseDetails];
      updatedDisburalDetails[activetabIndex][field_name] = date;
      setDisbursedDetails(updatedDisburalDetails);
    }
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      let activetabIndex = activeTab.split("_");
      activetabIndex = activetabIndex[1];
      const updatedDisburalDetails = [...disburseDetails];
      updatedDisburalDetails[activetabIndex][field_name] = +data?.id;
      setDisbursedDetails(updatedDisburalDetails);
    }
  };

  const is_disbursal_verified = useMemo(() => {
    let activetabIndex = activeTab.split("_");
    activetabIndex = activetabIndex[1];
    let singleTranch = disburseDetails[activetabIndex];
    let is_tranch_verified = singleTranch?.is_tranch_verified || 0;
    let split_id = singleTranch?.id || 0;
    return !split_id ||
      [1, 2, 3].includes(leadDetail.status_id) ||
      is_tranch_verified === "1"
      ? true
      : false;
  }, [activeTab, activeTab]);

  const activeTabIndex = useMemo(() => {
    const index = activeTab.split("_")[1];
    return index;
  }, [activeTab]);

  useEffect(() => {
    if (activeTabIndex > 0) {
      const updatedRes = leadDetail?.splitpayment[activeTabIndex] || null;
      setTranchConfirmation(updatedRes);
    }
  }, [activeTab, activeTabIndex]);

  const uploadDocsAndSave = (lead_id) => {
    const awsRes = [],
      leadDocArr = [];

    if (uploadedFiles.length) {
      let formData = new FormData();
      formData.append("dealer_id", lead_id);
      formData.append("upload_type", "dealer_docs");
      for (let imgFile of uploadedFiles) {
        formData.append("images", imgFile);
      }
      awsRes.push(
        GeneralService.uploadFilesToS3(formData, {
          "Content-Type": "multipart/form-data",
        })
      );
    } else {
      awsRes.push(
        Promise.resolve({
          data: { status: 200, message: "Success", data: ["not_updated"] },
        })
      );
    }

    if (uploadedFiles.length) {
      Q.allSettled(awsRes).then((uploadOutput) => {
        uploadOutput.forEach((res, index) => {
          let response = res.value || [];
          if (res.state === "fulfilled") {
            if (response.data.data.length) {
              let resData = response.data.data;
              resData.forEach((row, i) => {
                const loanDetailsSaveDoc = {
                  lead_id,
                  doc_path: row.file_url,
                  parent_doc_id: 9,
                  doc_id: DISBURSAL_DOC_ID,
                  doc_source: "web",
                  user_id: user_information?.data?.dealer_id,
                  customer_type: "customer",
                  customer_id: leadDetail?.customer?.customer_id,
                };
                leadDocArr.push(loanDetailsSaveDoc);
              });
            }
          }
        });
        if (leadDocArr.length) {
          let variables = {
            saveDocInput: {
              lead_id,
              lead_docs: leadDocArr,
            },
            api_called_by: "saathi_web",
            partner_id: user_information?.data?.dealer_id,
          };

          let mutation = gql`
            mutation save_doc(
              $saveDocInput: SaveDocInput!
              $api_called_by: API_CALL_BY!
              $partner_id: Float!
            ) {
              save_doc(
                saveDocInput: $saveDocInput
                api_called_by: $api_called_by
                partner_id: $partner_id
              ) {
                lead_id
                doc_id
                doc_path
              }
            }
          `;

          executeGraphQLMutationFinex(mutation, variables)
            .then((result) => {
              if (result && result.data) {
                toast.success("Document uploaded successfully");
              } else {
                console.log(result);
                toast.error("Internal server error");
              }
            })
            .catch((error) => {
              toast.error(error.message);
            });
        } else {
          toast.error("Error in image upload!");
        }
      });
    }
  };

  const handleSubmit = () => {
    const lead_id = leadDetail?.id;
    let disbursedAmount = +(
      (disburseDetails.length &&
        disburseDetails
          ?.map((v) => v.disbursed_amount)
          ?.reduce((a, b) => +a + +b)) ||
      0
    );
    let approved_amount = +leadDetail?.lead_details?.approved_amount || 0;
    if (disbursedAmount > approved_amount) {
      toast.error(
        "Disburse an amount greater than the sanctioned amount, please correct the amount before proceeding."
      );
      return false;
    }
    const isExistingDoc = leadDetail?.leaddocs?.some(
      (doc) => doc.doc_id === DISBURSAL_DOC_ID
    );
    if (!(isExistingDoc || uploadedFiles.length))
      return toast.warn("Please upload supporting docs");
    setIsSubmitDisabled(true);
    uploadDocsAndSave(lead_id);
    let tranche_type = disburseDetails?.[0]?.tranche_type || "";
    let loan_type = disburseDetails?.[0]?.loan_type || "";
    let loan_sub_type = disburseDetails?.[0]?.loan_sub_type?.length
      ? disburseDetails?.[0]?.loan_sub_type?.join(",")
      : "";
    let disbursement_done = tranche_type === 1 ? "1" : disburseMentDone;
    let activetabIndex = activeTab.split("_");
    activetabIndex = activetabIndex[1];
    let saveDisbursalData = disburseDetails[activetabIndex]
      ? [disburseDetails[activetabIndex]]
      : [];
    let disbursedRoi = saveDisbursalData.map((data) => data.disbursed_roi);
    let disbursed_roi = disbursedRoi.every(
      (disbursedRoi) => disbursedRoi >= 0 && disbursedRoi <= 99
    );

    if (disbursed_roi) {
      let reqBody = saveDisbursalData.map((disbural) => {
        return {
          ...(disbural?.id && { id: disbural?.id }),
          lead_id: lead_id,
          product_sub_type: +disbural?.product_sub_type || 0,
          disbursed_amount: disbural?.disbursed_amount,
          disbursed_tenure: disbural?.disbursed_tenure,
          disbursal_remark: disbural?.disbursal_remark,
          disbursed_id: disbural?.disbursed_id,
          disbursed_date: dateFormat(disbural?.disbursed_date, "yyyy-mm-dd"),
          disbursed_roi: disbural.disbursed_roi || "0",
        };
      });

      let mutation = UPDATE_LEAD_STATUS,
        variables = {
          api_called_by: "saathi_web",
          partner_id: user_information?.data.dealer_id,
          UpdateLeadStatus: {
            tranche_type: tranche_type,
            loan_type: loan_type,
            loan_sub_type: loan_sub_type,
            disbursement_done: disbursement_done,
            update_type: "status",
            status_id: [4],
            // after_verified: is_disbursal_verified ? 1 : 0,
            lead_id: lead_id,
            user_id: user_information?.data?.dealer_id,
            disbursal_form: reqBody,
          },
        };

      executeGraphQLMutationFinex(mutation, variables)
        .then((resp) => {
          let response = resp?.data?.UpdateLeadStatus || null;
          if (response && !response.success) {
            throw new Error(response.error || "Something went wrong");
          }
          toast.success("Success");
          setNewPayment(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          toast.error(err.message);
          setIsSubmitDisabled(false);
        });
    } else {
      toast.error("Disbursed roi between 0 and 99.");
      setIsSubmitDisabled(false);
      return false;
    }
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles((current) => [...current, ...files]);
  };

  const handleOpenNewTab = (event) => {
    const { src } = event.target;
    if (src) {
      window.open(src, "_blank");
    } else {
      const pdfUrl = event.target.getAttribute("data-docurl");
      window.open(pdfUrl, "_blank");
    }
  };

  // @index - consider doc_id if existing OR index if newly added doc.
  const handleRemoveDocument = async (docType, index) => {
    try {
      const isConfirmed = window.confirm(
        "Confirm, do you want to remove this document"
      );
      if (isConfirmed) {
        if (docType === "new") {
          const uploadedDocsClone = [...uploadedFiles];
          uploadedDocsClone.splice(index, 1);
          setUploadedFiles(uploadedDocsClone);
        } else {
          const lead_id = leadDetail?.id;
          const mutation = gql`
            mutation update_doc($updateDocInput: UpdateDocInput!) {
              update_doc(updateDocInput: $updateDocInput) {
                id
                lead_id
                doc_id
              }
            }
          `;
          const variables = {
            updateDocInput: {
              id: index,
              lead_id,
              status: "0",
              parent_doc_id: 0,
              doc_id: 0,
              doc_source: "web",
              user_id: user_information?.data?.dealer_id,
              tab_name: "first_tab",
            },
          };

          await executeGraphQLMutationFinex(mutation, variables);
          const exUploaded = [...existingUploadedFiles];
          const updatedDocs =
            exUploaded.filter((doc) => doc?.id !== index) || [];

          setExistingUploadedFiles(updatedDocs);
          toast.success("Success");
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const IS_LOST = leadDetail?.status_id == 5 ? true : false;
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 90);

  return (
    <>
      <div>
        <div>
          <h5>Disbursal Details</h5>
          <div className="tab-list">
            <div className="commontabs">
              {disburseDetails.length &&
                disburseDetails.map((coApplicant, index) =>
                  disburseDetails[index].tranche_type ? (
                    <div
                      className={
                        activeTab === `tranchtype_${index}`
                          ? "commontabsItem active"
                          : "commontabsItem"
                      }
                      onClick={() => setActiveTab(`tranchtype_${index}`)}
                    >
                      {" "}
                      {`Disbursal-${index + 1}`}
                    </div>
                  ) : (
                    " New Tranche"
                  )
                )}

              {leadDetail?.disbursement_done === "1" ||
              leadDetail?.tranche_type == "1" ||
              IS_LOST ? (
                ""
              ) : (
                <div className="plusiconstyle" onClick={addMoreFields}>
                  <div className="coapplicantadd">
                    <span>+</span> Disbursal
                  </div>
                </div>
              )}
            </div>
          </div>

          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">
              {DisbursedDetailsForm.map((field) =>
                disburseDetails.map(
                  (splitPaymentDetailData, index) =>
                    `tranchtype_${index}` === activeTab &&
                    (["text", "pattern-format"].includes(field.type) ? (
                      <fieldset class="form-filed col-md-6 ">
                        <FormsyInputField
                          id={field.id}
                          name={field.name}
                          type={field.type}
                          value={
                            splitPaymentDetailData &&
                            splitPaymentDetailData[field.name]
                          }
                          placeholder=" "
                          label={field.label}
                          maxLength={field.maxLength}
                          onChange={
                            field.type === "pattern-format"
                              ? (data) =>
                                  handlePatternFormatChange(data, field.name)
                              : handleInputChange
                          }
                          format={field.ApplyNumberformat ? field.format : ""}
                          validations={field.validations}
                          validationError={
                            splitPaymentDetailData[field.name]
                              ? field.validationError
                              : ""
                          }
                          required={field.required}
                        />
                      </fieldset>
                    ) : ["number-format"].includes(field.type) ? (
                      <fieldset class="form-filed col-md-6 ">
                        <FormsyInputField
                          id={field.id}
                          name={field.name}
                          type={field.type}
                          value={
                            splitPaymentDetailData &&
                            splitPaymentDetailData[field.name]
                          }
                          placeholder=" "
                          thousandsGroupStyle="lakh"
                          thousandSeparator={","}
                          decimalSeparator={"."}
                          label={field.label}
                          maxLength={field.maxLength}
                          onChange={
                            field.type === "number-format"
                              ? (data) =>
                                  handlePatternFormatChange(data, field.name)
                              : handleInputChange
                          }
                          format={field.ApplyNumberformat ? field.format : ""}
                          validations={field.validations}
                          validationError={
                            splitPaymentDetailData[field.name]
                              ? field.validationError
                              : ""
                          }
                          required={field.required}
                        />
                      </fieldset>
                    ) : field.type === "date-picker" ? (
                      <fieldset class="form-filed col-md-6 ">
                        <FormsyDatePicker
                          name={field.name}
                          selectedDate={
                            splitPaymentDetailData &&
                            splitPaymentDetailData[field.name] &&
                            new Date(splitPaymentDetailData[field.name])
                          }
                          placeholder={field.label}
                          minDate={
                            new Date(leadDetail?.lead_details?.approval_date)
                          }
                          maxDate={new Date()}
                          value={
                            splitPaymentDetailData &&
                            splitPaymentDetailData[field.name]
                              ? dateFormat(
                                  splitPaymentDetailData[field.name],
                                  "dd mmm, yyyy"
                                )
                              : null
                          }
                          onDateSelect={(date) =>
                            handleDateChange(date, field.name)
                          }
                          dateFormat="dd MMM, yyyy"
                          validations={field.validations}
                          validationError={
                            splitPaymentDetailData[field.name]
                              ? field.validationError
                              : ""
                          }
                          required={field.required}
                          hasError={
                            splitPaymentDetailData.hasOwnProperty(field.name) &&
                            !splitPaymentDetailData[field.name]
                              ? true
                              : false
                          }
                        />
                      </fieldset>
                    ) : field.type === "dropdown" ? (
                      <fieldset class="single-select col-md-6">
                        <FormsySelect
                          name={field.name}
                          id={field.id}
                          inputProps={{
                            options:
                              ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder: field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value:
                              ALL_OPTIONS &&
                              ALL_OPTIONS[field.optionsKey] &&
                              ALL_OPTIONS[field.optionsKey].filter(
                                ({ id }) =>
                                  id == splitPaymentDetailData[field.name]
                              ),
                          }}
                          required={field.required}
                          value={
                            splitPaymentDetailData &&
                            splitPaymentDetailData[field.name]
                          }
                          onChange={(data) =>
                            handleSelectChange(data, field.name)
                          }
                        />
                      </fieldset>
                    ) : field.type === "multiselect" ? (
                      <fieldset class="single-select col-md-6">
                        <label htmlFor={field.id}>{field.label}</label>
                        <MultiSelect
                          options={ALL_OPTIONS && ALL_OPTIONS[field.optionsKey]}
                          placeholder={field.label}
                          name={field.name}
                          id={field.id}
                          optionLabel="label"
                          optionValue="id"
                          values={splitPaymentDetailData[field.name]}
                          onChange={handleMultiSelectChange}
                          isDisabled={
                            field.readOnly || activeTab != "tranchtype_0"
                          }
                        />
                      </fieldset>
                    ) : field.type === "textarea" ? (
                      <fieldset class="col-md-12">
                        <div class="material">
                          <label>{field.label}</label>
                          <textarea
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            value={
                              splitPaymentDetailData &&
                              splitPaymentDetailData[field.name]
                            }
                            placeholder=" "
                            onChange={handleInputChange}
                            class="form-input"
                          ></textarea>
                        </div>
                      </fieldset>
                    ) : field.type === "radio" &&
                      field.name === "tranche_type" &&
                      splitPaymentDetailData["loan_sub_type"]?.length !== 2 ? (
                      <fieldset class="form-filed col-md-12 ">
                        <div style={{ marginBottom: 10 }}>Disbursal Type*</div>
                        <div className="loanfulfillment">
                          <div className="loaninput">
                            <input
                              type="radio"
                              required
                              disabled={
                                leadDetail.is_disbursal_verified === "1"
                              }
                              id="tranche_type"
                              name="tranchtype"
                              value="1"
                              checked={
                                splitPaymentDetailData &&
                                splitPaymentDetailData[field.name] == "1"
                                  ? true
                                  : false
                              }
                              onChange={handleInputChange}
                            />
                            <label htmlFor="single"> Single disbursal</label>
                          </div>
                          <div className="loaninput">
                            <input
                              type="radio"
                              required
                              disabled={
                                leadDetail.is_disbursal_verified === "1"
                              }
                              id="tranche_type"
                              name="tranchtype"
                              value="2"
                              checked={
                                splitPaymentDetailData &&
                                splitPaymentDetailData[field.name] == "2"
                                  ? true
                                  : false
                              }
                              onChange={handleInputChange}
                            />
                            <label htmlFor="Tranche"> Tranche disbursal</label>
                          </div>
                        </div>
                      </fieldset>
                    ) : field.type === "radio" &&
                      field.name === "product_sub_type" &&
                      splitPaymentDetailData["loan_sub_type"]?.length === 2 ? (
                      <fieldset class="form-filed col-md-12 ">
                        <div style={{ marginBottom: 10 }}>
                          Product Sub Type*
                        </div>

                        <div className="loanfulfillment">
                          {splitPaymentDetailData["loan_sub_type"].includes(
                            "1"
                          ) ? (
                            <div className="loaninput">
                              <input
                                type="radio"
                                required
                                id="product_sub_type"
                                name="product_sub_type"
                                value="1"
                                checked={
                                  splitPaymentDetailData &&
                                  splitPaymentDetailData[field.name] == "1"
                                    ? true
                                    : false
                                }
                                onChange={handleInputChange}
                              />
                              <label htmlFor="single"> Balance Transfer</label>
                            </div>
                          ) : (
                            ""
                          )}
                          {splitPaymentDetailData["loan_sub_type"].includes(
                            "2"
                          ) ? (
                            <div className="loaninput">
                              <input
                                type="radio"
                                required
                                id="product_sub_type"
                                name="product_sub_type"
                                value="2"
                                checked={
                                  splitPaymentDetailData &&
                                  splitPaymentDetailData[field.name] == "2"
                                    ? true
                                    : false
                                }
                                onChange={handleInputChange}
                              />
                              <label htmlFor="Tranche"> Top up</label>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </fieldset>
                    ) : field.type === "checkbox" &&
                      activeTab ===
                        `tranchtype_${disburseDetails.length - 1}` &&
                      splitPaymentDetailData?.tranche_type === "2" ? (
                      <fieldset class="col-md-12 ">
                        <div className="loggedcheck">
                          <br></br>
                          {/* <input type='checkbox' onChange={handleDisbursalDone} id={field.id} name={field.name} value='1' disabled={lead?.leadDetail?.disbursement_done === '1'} checked={disburseMentDone === '1' ? true : false} /> */}
                          <input
                            type="checkbox"
                            onChange={handleDisbursalDone}
                            id={field.id}
                            name={field.name}
                            value="1"
                            checked={disburseMentDone === "1" ? true : false}
                          />
                          <label htmlFor="credit">{field.label}</label>
                        </div>
                      </fieldset>
                    ) : null)
                )
              )}
            </div>
            <br></br>
            <br></br>
            <div className="">
              <h5> Upload Disbursal Docs</h5>
              <ul>
                {disbursalDocuments?.length > 0 &&
                  disbursalDocuments?.map((fields, index) => (
                    <li key={index}>
                      <div className="col-sm-2">
                        <div className="displayflex">
                          <div className="updatefileupload">
                            <input
                              type="file"
                              id={`${index}_fileInput`}
                              onChange={handleFileChange}
                              required={fields.is_required}
                              accept="image/*,.pdf"
                              multiple
                            />
                            <label
                              htmlFor={`${index}_fileInput`}
                              className="file-label"
                            >
                              <span>
                                <img
                                  src={require(`../../../src/saathi-web/images/uploadimg.svg`)}
                                  alt="not"
                                  width={20}
                                  height={20}
                                />
                              </span>
                              <div id="fileName" className="statusdoc">
                                Upload Doc
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>

              <div className="displayflex">
                {/* Already uploaded docs */}
                {existingUploadedFiles?.length > 0 &&
                  existingUploadedFiles.map((doc, index) => {
                    if (doc.doc_id === DISBURSAL_DOC_ID) {
                      return (
                        <div key={index} className="showimage">
                          {doc.ext === "pdf" || doc.ext === "PDF" ? (
                            <i
                              data-docurl={doc?.doc_path}
                              className="ic-pdf-file icons-normal"
                              onClick={handleOpenNewTab}
                              style={{ fontSize: "30px" }}
                            ></i>
                          ) : (
                            <img
                              src={doc.doc_path}
                              width={70}
                              height={40}
                              alt={`Document ${doc.doc_id}`}
                              onClick={handleOpenNewTab}
                            />
                          )}
                          <i
                            className="close-icon"
                            onClick={() =>
                              handleRemoveDocument("existing", doc.id)
                            }
                          >
                            &times;
                          </i>
                        </div>
                      );
                    }
                    return null;
                  })}

                {/* Newly added ones */}
                {uploadedFiles?.map((file, index) => {
                  const docUrl = URL.createObjectURL(file);
                  const fileType = file.type;
                  const isPDF = fileType === "application/pdf";
                  return (
                    <div key={`${index}_uploadedDoc`} className="showimage">
                      {isPDF ? (
                        <i
                          data-docurl={docUrl}
                          className="ic-pdf-file icons-normal"
                          onClick={handleOpenNewTab}
                          style={{ fontSize: "30px" }}
                        ></i>
                      ) : (
                        <img
                          src={docUrl}
                          width={70}
                          height={40}
                          onClick={handleOpenNewTab}
                        />
                      )}
                      <i
                        className="close-icon"
                        onClick={() => handleRemoveDocument("new", index)}
                      >
                        &times;
                      </i>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="updatestatusflex">
              <button
                className="submitbtnnewleadstatus"
                disabled={isSubmitDisabled}
              >
                Submit
              </button>
            </div>
          </Formsy>
        </div>
      </div>
    </>
  );
};

export default DisbursalDetails;
