import { gql } from '@apollo/client';

export const LEAD_CREATION_FORM = gql`
    mutation create_loan($newLeadInput: CreateLoanInput!, $api_called_by: API_CALL_BY!,$partner_id: Float) {
        create_loan(newLeadInput: $newLeadInput, api_called_by: $api_called_by,partner_id: $partner_id) {
            id
            message
            is_duplicate
        }
    }
`;

export const ASSIGN_LEAD_CSV = gql`
    mutation update_assign_lead($BulkAssignLeadInput: BulkAssignLeadInput!){
        update_assign_lead(bulkAssignLeadInput: $BulkAssignLeadInput) 
    }
`;


export const LEAD_ASSIGN = gql`
  mutation AssignNewPartner(
    $partner_id: Float!,
    $api_called_by: API_CALL_BY!,
    $assigned_partner_id: Float!,
    $lead_ids: [Int!]!
  ) {
    assign_new_partner(
      partner_id: $partner_id,
      api_called_by: $api_called_by,
      filterParams: {
        assigned_partner_id: $assigned_partner_id,
        lead_ids: $lead_ids
      }
    ) {
      message
    }
  }
`;

export const SAVE_LOAN_OFFER = gql`
  mutation save_loan_offer(
    $partner_id: Float!,
    $api_called_by: API_CALL_BY!,
    $loanOfferInput: [SaveOfferInput!]!
  ) {
    save_loan_offer(
      partner_id: $partner_id,
      api_called_by: $api_called_by,
      loanOfferInput: $loanOfferInput
    ) {
      lead_id
    }
  }
`;
